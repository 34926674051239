<template>
  <container>
    <ul
      class="z-30 flex flex-wrap h-full items-center cursor-pointer"
      data-test="navbar"
    >
      <li
        v-for="({ title, slug, url, newTab }, index) in menu"
        :key="`${title}-${index}`"
        class="me-6 text-base font-semibold"
      >
        <div class="flex">
          <ui-link
            :url="url || slug"
            :blank="newTab"
            class="text-primary flex items-center"
          >
            {{ title }}
          </ui-link>
        </div>
      </li>
    </ul>
  </container>
</template>

<script>
import VueTypes from 'vue-types'
import { Container } from '~/components/grid'
import { UiLink } from '~/components/atoms'

export default {
  components: {
    Container,
    UiLink,
  },
  props: {
    menu: VueTypes.arrayOf(Object).def([]),
  },
}
</script>
