<template>
  <header class="bg-white mb-4 lg:mb-8">
    <div class="py-3 border-b border-gray">
      <service-bar type="simple" :logo="logo" />
    </div>
    <div class="block md:hidden">
      <portal-target name="serviceCostsOverlay" />
    </div>
  </header>
</template>

<script>
import ServiceBar from './service-bar'

export default {
  components: {
    ServiceBar,
  },
  props: {
    logo: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>
