import { PropOptions } from 'vue'
import { IHeader } from '~/models/components/Header'
import { ICountryModel } from '~/models/components/Country'

export type THeader = 'simple' | 'base' | 'country-restricted'

export const menu = {
  type: Array,
  default: () => [],
} as PropOptions<IHeader['menu'][]>

export const country = {
  type: Object,
  default: () => ({}),
} as PropOptions<ICountryModel | Record<string, unknown>>

export const logo = {
  type: Object,
  default: () => ({}),
} as PropOptions<IHeader['logo'] | Record<string, unknown>>

export const usps = {
  type: Array,
  default: () => [],
} as PropOptions<IHeader['usps']>

export const type = {
  type: String,
  default: 'base',
} as PropOptions<THeader>

export const secondaryLinks = {
  type: Array,
  default: () => [],
} as PropOptions<IHeader['secondaryLinks']>

export const lang = {
  type: String,
  default: '',
}

export const hideUsps = {
  type: Boolean,
  default: false,
}

export const showBreadcrumbs = {
  type: Boolean,
  default: false,
}

export const redirectCclToHome = {
  type: Boolean,
  default: false,
}

export const legalLink = {
  type: Object,
  default: () => ({}),
}
