export const RECAPTCHA_SIGN_IN_ACTION = 'sign_in'
export enum AUTH_MODAL_TYPES {
  LOGIN = 'login',
  REGISTER = 'register',
  FORGOT_PASSWORD = 'forgot-password',
  ACCOUNT_VERIFICATION = 'verify-account',
}

export enum SIGN_IN_METHODS {
  FACEBOOK = 'Facebook',
  GOOGLE = 'Google',
  SIGN_IN_WITH_APPLE = 'SignInWithApple',
}
