<template>
  <header class="bg-white">
    <container class="py-3 lg:py-6 border-b border-gray">
      <row class="justify-between">
        <column class="justify-center w-1/2 md:w-1/3 lg:w-2/12">
          <logo :logo="businessLogo" />
        </column>
        <column class="items-end justify-end w-1/2 py-3 md:w-2/3 lg:w-10/12 ">
          <ul v-if="!useMobileMenu" class="flex text-primary">
            <li v-for="item in menu" :key="item.order" class="mr-4 last:mr-0">
              <ui-link
                :url="item.slug"
                :has-custom-behavior="item.hasCustomBehaviour"
                :blank="item.openBlank"
                wrapper
                class="flex flex-wrap"
              >
                {{ item.title }}
              </ui-link>
            </li>
          </ul>
          <icon
            v-if="useMobileMenu"
            color="primary"
            class="cursor-pointer"
            icon="hamburger"
            size="base"
            data-test="hamburger-menu"
            @click="onShowMobileMenu"
          />
        </column>
      </row>
    </container>
    <div class="bg-gray-light">
      <breadcrumb-bar
        :trustpilot-type="`business`"
        always-show-trustpilot
        :data-test="`header-breadcrumbBar`"
      />
      <mobile-menu
        v-if="useMobileMenu"
        :items="menu"
        :state="mobileMenuVisibility"
        :redirect-to-home="redirectCclToHome"
        :data-test="`header-mobileMenu`"
        is-business-page
        @menu__close:click="onHideMobileMenu"
      />
    </div>
  </header>
</template>

<script>
import { mapGetters } from 'vuex'
import BreadcrumbBar from './breadcrumb-bar'
import { logo, redirectCclToHome } from './props'
import { Row, Column, Container } from '~/components/grid'
import { Icon, UiLink } from '~/components/atoms'
import { Logo } from '~/components/molecules'
import { MobileMenu } from '~/components/organisms'

export const SHOW_MOBILE_MENU_SIZES = ['xs', 'sm', 'md']

export default {
  components: {
    Container,
    Row,
    Column,
    Logo,
    Icon,
    UiLink,
    BreadcrumbBar,
    MobileMenu,
  },
  props: {
    logo,
    redirectCclToHome,
  },
  data() {
    return {
      businessLogo: {
        __typename: 'Asset',
        title: 'cut-logo',
        contentType: 'image/svg+xml',
        url: this.getImageUrl(),
        width: 136,
        height: 18,
      },
      menu: [
        {
          order: 0,
          title: this.$t('business.menu.recharge'),
          slug: '',
          hasCustomBehaviour: false,
          openBlank: false,
          reloadPage: true,
        },
        {
          order: 1,
          title: this.$t('business.menu.request_order'),
          slug: '#request-order',
          hasCustomBehaviour: true,
          openBlank: false,
        },
        {
          order: 2,
          title: this.$t('business.menu.about_us'),
          slug:
            'https://company.recharge.com/?utm_source=recharge&utm_medium=business',
          hasCustomBehaviour: false,
          openBlank: true,
        },
      ],
    }
  },
  computed: {
    ...mapGetters('ui/mobile-menu', ['mobileMenuVisibility']),
    useMobileMenu() {
      return SHOW_MOBILE_MENU_SIZES.includes(this.$mq)
    },
  },
  methods: {
    getImageUrl() {
      // eslint-disable-next-line global-require
      return require(`@/assets/images/business/business-logo.png`)
    },
    onShowMobileMenu() {
      this.$store.dispatch('ui/mobile-menu/setMobileMenuVisibility', 'visible')
      const bodyHTMLElement = document.querySelector('body')
      bodyHTMLElement.classList.add('overflow-hidden')
    },
    onHideMobileMenu() {
      this.$store.dispatch('ui/mobile-menu/setMobileMenuVisibility', 'hidden')
      const bodyHTMLElement = document.querySelector('body')
      bodyHTMLElement.classList.remove('overflow-hidden')
    },
  },
}
</script>
