<template>
  <header class="bg-white mb-4 lg:mb-8">
    <div
      class="py-3 border-b border-gray flex flex-row items-center content-between w-full"
    >
      <service-bar type="wide" :logo="logo" />
      <column
        class="w-full xl:w-5/12 flex-start me-10 px-2 hidden xl:block lg:block"
      >
        <client-only>
          <locale-only :except="ArabicLocales">
            <vue-trustpilot
              :identifier="trustpilotIdentifier"
              class="xl:-me-20 "
              :data-locale="trustpilotLocale"
            />
          </locale-only>
        </client-only>
      </column>
    </div>
  </header>
</template>

<script>
import { mapGetters } from 'vuex'
import ServiceBar from './service-bar'
import LocaleOnly, { ArabicLocales } from '~/components/utils/locale-only'
import { Column } from '~/components/grid'

export default {
  components: {
    ServiceBar,
    LocaleOnly,
    Column,
  },
  props: {
    logo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return { ArabicLocales }
  },
  computed: {
    ...mapGetters('context', [
      'trustpilotLocale',
      'locale',
      'direction',
      'isGlobalMarketplace',
      'marketplacePrefix',
    ]),
    trustpilotIdentifier() {
      return this.$mq === 'sm'
        ? `header-mobile-${this.direction}`
        : `header-desktop-${this.direction}`
    },
  },
}
</script>
