
import { mapGetters } from 'vuex'
import {
  menu,
  country,
  logo,
  usps,
  type,
  secondaryLinks,
  redirectCclToHome,
  lang,
  hideUsps,
  showBreadcrumbs,
} from './props'
import HeaderBase from './base.vue'
import HeaderSimple from './simple.vue'
import HeaderWide from './wide.vue'
import HeaderBusiness from './business.vue'

export default {
  name: 'UiHeader',
  components: {
    HeaderBase,
    HeaderSimple,
    HeaderWide,
    HeaderBusiness,
  },
  props: {
    menu,
    country,
    logo,
    usps,
    type,
    secondaryLinks,
    lang,
    hideUsps,
    showBreadcrumbs,
    redirectCclToHome,
  },
  computed: {
    ...mapGetters('contentful', ['helpLink']),
  },
}
