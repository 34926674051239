<template>
  <div class="md:bg-gray-light py-2 md:py-3">
    <container>
      <row class="justify-between items-center">
        <column class="xl:flex xl:w-9/12">
          <breadcrumbs color="grey" invert-color no-padding />
        </column>
        <column
          class="xl:w-3/12 flex-start"
          :class="[alwaysShowTrustpilot ? '' : ' hidden md:block']"
        >
          <client-only>
            <locale-only :except="ArabicLocales">
              <vue-trustpilot
                :identifier="trustpilotIdentifier"
                class="xl:-me-20 "
                :data-locale="trustpilotLocale"
              />
            </locale-only>
          </client-only>
        </column>
      </row>
    </container>
  </div>
</template>

<script>
import VueTypes from 'vue-types'
import { mapGetters } from 'vuex'
import { Row, Column, Container } from '~/components/grid'
import { Breadcrumbs } from '~/components/molecules'
import LocaleOnly, {
  ArabicLocales,
} from '~/components/utils/locale-only/index.vue'

export default {
  components: {
    Row,
    Column,
    Container,
    Breadcrumbs,
    LocaleOnly,
  },
  props: {
    usps: VueTypes.array.def([]),
    type: VueTypes.oneOf(['base', 'simple']).def('base'),
    trustpilotType: {
      type: String,
      default: 'header',
    },
    alwaysShowTrustpilot: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      ArabicLocales,
    }
  },
  computed: {
    ...mapGetters('context', ['trustpilotLocale', 'direction']),
    trustpilotIdentifier() {
      return this.$mq === 'sm'
        ? `${this.trustpilotType}-mobile-${this.direction}`
        : `${this.trustpilotType}-desktop-${this.direction}`
    },
  },
}
</script>
