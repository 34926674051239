<template>
  <header class="bg-white z-10">
    <div class="relative">
      <service-bar
        :lang="lang"
        :legal-link="legalLink"
        :logo="logo"
        :country="country"
        :data-test="`header-serviceBar`"
        @service-bar:show-mobile-menu="onShowMobileMenu"
        @service-bar:show-country-select="showCountrySelect"
      />
      <navbar
        class="hidden lg:flex h-10"
        :menu="menu"
        :data-test="`header-navbar`"
      />
    </div>
    <portal-target
      :name="portals.PORTAL_WEB_TO_APP_BANNER"
      data-test="web-to-app-portalTarget"
    />
    <usps-bar v-if="!hideUsps" :usps="usps" />
    <breadcrumb-bar
      v-if="showBreadcrumbs"
      :data-test="`header-breadcrumbBar`"
    />
    <mobile-menu
      v-if="useMobileMenu"
      :items="menu"
      :secondary-links="secondaryLinks"
      :state="mobileMenuVisibility"
      :redirect-to-home="redirectCclToHome"
      :data-test="`header-mobileMenu`"
      @menu__close:click="onHideMobileMenu"
    />
    <language-selector
      v-if="isCountrySelectVisible && !isGlobalMarketplace"
      :data-test="`header-languageSelector`"
    />
    <portal-target
      v-if="!isGlobalMarketplace"
      :name="overlayName"
      data-test="`header-portalTarget1`"
    />

    <portal-target
      :name="portals.PORTAL_AUTHENTICATION_MODAL"
      data-test="`header-portalTarget2`"
    />

    <authentication-modal
      v-if="showAuthModal"
      data-test="`header-authenticationModal`"
      @auth-modal-close="closeAuthOverlay"
      @close-mobile-menu="onHideMobileMenu"
    />

    <overlay-with-nesting
      v-if="isGlobalMarketplace"
      :name="cclOverlayName"
      :title="$t('organisms.language_selector.title')"
      :desc="$t('organisms.language_selector.description')"
      data-test="`header-overlayWithNesting`"
    >
      <country-currency-language-selector
        origin="header"
        :redirect-to-home="redirectCclToHome"
        data-test="`header-countryCurrencyLanguageSelector`"
      />
    </overlay-with-nesting>
  </header>
</template>

<script>
import { mapGetters } from 'vuex'
import UspsBar from './usps-bar'
import Navbar from './navbar'
import BreadcrumbBar from './breadcrumb-bar'
import ServiceBar from './service-bar'
import {
  menu,
  country,
  logo,
  usps,
  type,
  secondaryLinks,
  lang,
  hideUsps,
  showBreadcrumbs,
  redirectCclToHome,
  legalLink,
} from './props'
import { portals } from '~/enums'
import { MobileMenu, AuthenticationModal } from '~/components/organisms'

import { AUTH_MODAL_TYPES } from '~/utils/constants/auth'
import { OverlayWithNesting } from '~/components/molecules'
const LanguageSelector = () =>
  import('~/components/organisms/language-selector')

const CountryCurrencyLanguageSelector = () =>
  import('~/components/organisms/country-currency-language-selector')

export const SHOW_MOBILE_MENU_SIZES = ['xs', 'sm', 'md', 'lg']
export default {
  name: 'Base',
  components: {
    MobileMenu,
    LanguageSelector,
    CountryCurrencyLanguageSelector,
    UspsBar,
    Navbar,
    BreadcrumbBar,
    ServiceBar,
    OverlayWithNesting,
    AuthenticationModal,
  },
  props: {
    menu,
    country,
    lang,
    logo,
    usps,
    secondaryLinks,
    type,
    hideUsps,
    showBreadcrumbs,
    legalLink,
    redirectCclToHome,
  },
  data() {
    return {
      cclOverlayName: 'CCL-selector-overlay',
      portals,
    }
  },
  computed: {
    ...mapGetters('context', ['isGlobalMarketplace']),
    ...mapGetters('ui', ['overlayVisibility', 'overlayName']),
    ...mapGetters('ui/mobile-menu', ['mobileMenuVisibility']),
    ...mapGetters('user', ['isAuthenticated']),
    useMobileMenu() {
      return SHOW_MOBILE_MENU_SIZES.includes(this.$mq)
    },
    isCountrySelectVisible() {
      return (
        this.overlayName === this.portalName &&
        this.overlayVisibility === 'visible'
      )
    },
    isVerificationModal() {
      return this.$route.query.auth === AUTH_MODAL_TYPES.ACCOUNT_VERIFICATION
    },
    showAuthModal() {
      return !!(
        this.$route.query.auth &&
        (!this.isAuthenticated || this.isVerificationModal)
      )
    },
    portalName() {
      return this.isGlobalMarketplace
        ? portals.PORTAL_NAME_COUNTRY_CURRENCY_LANGUAGE_SELECTOR
        : portals.PORTAL_NAME_LANGUAGE_SELECTOR
    },
  },
  watch: {
    isAuthenticated: {
      handler(val) {
        if (val && !!this.$route.query.auth && !this.isVerificationModal) {
          this.$router.replace({ auth: null })
        }
      },
      immediate: true,
    },
  },
  methods: {
    onShowMobileMenu() {
      this.$store.dispatch('ui/mobile-menu/setMobileMenuVisibility', 'visible')
      const bodyHTMLElement = document.querySelector('body')
      bodyHTMLElement.classList.add('overflow-hidden')
    },
    onHideMobileMenu() {
      this.$store.dispatch('ui/mobile-menu/setMobileMenuVisibility', 'hidden')
      const bodyHTMLElement = document.querySelector('body')
      bodyHTMLElement.classList.remove('overflow-hidden')
    },
    showCountrySelect() {
      if (!this.isGlobalMarketplace) {
        this.$store.dispatch('ui/toggleOverlay', {
          name: this.portalName,
          visibility: 'visible',
        })
      } else {
        this.$store.dispatch('ui/showNestingOverlay', {
          name: this.cclOverlayName,
        })
      }
    },
    closeAuthOverlay() {
      const routerQueryParams = { ...this.$route.query }
      delete routerQueryParams.auth
      if (routerQueryParams.ref) delete routerQueryParams.ref
      this.$router.replace({ query: routerQueryParams })
    },
  },
}
</script>
