<template>
  <container
    :override-class="type === 'wide' ? `w-full mx-auto` : ''"
    :without-insets="type === 'wide'"
  >
    <row class="justify-between">
      <column
        :class="
          `justify-center ${
            type === 'wide' ? 'w-1/2' : 'w-1/2 md:w-2/3 lg:w-2/12'
          }`
        "
      >
        <div :class="type === 'wide' ? 'ps-10' : ''">
          <logo :logo="logo" :without-link="logoWithoutLink" />
        </div>
      </column>

      <column
        v-if="type === 'base'"
        class="items-end justify-end w-1/2 py-3 md:w-1/3 lg:w-10/12"
      >
        <div class="flex items-center">
          <ui-link
            v-if="legalLink"
            :url="legalLink.url"
            :blank="legalLink.newTab"
            :rel="legalLink.rel"
            data-test="legal-link"
            class="hidden font-medium lg:flex text-primary me-10"
          >
            {{ legalLink.title || $t('general.need_some_help') }}
          </ui-link>
          <locale
            :lang="lang"
            :country="country.abv"
            :currency="currencyCode"
            @locale:click="$emit('service-bar:show-country-select')"
          />

          <template v-if="isAuthenticated">
            <account-header-menu
              class="hidden lg:flex"
              :class="{
                'ml-10': direction === 'ltr',
                'mr-10': direction === 'rtl',
              }"
            />
          </template>
          <template v-else>
            <ui-button
              v-if="!isRafInvitationPage"
              :url="authModalUrlSignIn"
              data-test="sign-in"
              class="hidden lg:flex ml-10"
              type="secondary-dark"
              is-slim
              slim-y-padding
            >
              {{ $t('account.overview.sign_in') }}
            </ui-button>
            <ui-button
              v-if="!isRafInvitationPage"
              :url="authModalUrlSignUp"
              data-test="sign-up"
              class="hidden lg:flex ml-4"
              type="primary"
              is-slim
              slim-y-padding
            >
              {{ $t('authentication.navigation.register_label') }}
            </ui-button>
          </template>
          <icon
            v-if="useMobileMenu"
            color="primary"
            class="cursor-pointer ms-4 border-s border-gray ps-4"
            icon="hamburger"
            size="base"
            data-test="hamburger-menu"
            @click.prevent="$emit('service-bar:show-mobile-menu')"
          />
        </div>
      </column>
    </row>
  </container>
</template>

<script>
import VueTypes from 'vue-types'
import { mapGetters } from 'vuex'
import { SHOW_MOBILE_MENU_SIZES } from './base.vue'
import { Icon, UiLink, UiButton } from '~/components/atoms'
import { Row, Column, Container } from '~/components/grid'
import { Logo, Locale } from '~/components/molecules'
import { AccountHeaderMenu } from '~/components/organisms'
import { AUTH_MODAL_TYPES } from '~/utils/constants/auth'
import referAFriend from '~/mixins/refer-a-friend'

export default {
  components: {
    AccountHeaderMenu,
    Row,
    Column,
    Container,
    Logo,
    Locale,
    Icon,
    UiLink,
    UiButton,
  },
  mixins: [referAFriend],
  props: {
    country: VueTypes.object.def({}),
    lang: VueTypes.string,
    legalLink: VueTypes.object.def({}),
    logo: VueTypes.object.def({}),
    type: VueTypes.oneOf(['base', 'simple', 'wide']).def('base'),
    logoWithoutLink: Boolean,
  },
  computed: {
    ...mapGetters('context', ['currency', 'direction']),
    ...mapGetters('user', ['isAuthenticated']),
    currencyCode() {
      return this.currency.code
    },
    useMobileMenu() {
      return SHOW_MOBILE_MENU_SIZES.includes(this.$mq)
    },
    authModalUrlSignIn() {
      return this.$contextPath(
        `${this.$route.path}?auth=${AUTH_MODAL_TYPES.LOGIN}`
      )
    },
    authModalUrlSignUp() {
      return this.$contextPath(
        `${this.$route.path}?auth=${AUTH_MODAL_TYPES.REGISTER}`
      )
    },
  },
}
</script>
