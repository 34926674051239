<template>
  <div class="bg-gray-light py-2 md:py-3">
    <container>
      <row class="flex-between">
        <column class="hidden xl:flex xl:w-7/12">
          <usps-list :usps="usps" :default-usps="1" />
        </column>
        <column class="w-full xl:w-5/12 flex-start">
          <header-carousel />
        </column>
      </row>
    </container>
  </div>
</template>

<script>
import VueTypes from 'vue-types'
import { Row, Column, Container } from '~/components/grid'
import { UspsList } from '~/components/molecules'
import { HeaderCarousel } from '~/components/organisms'

export default {
  components: {
    Row,
    Column,
    Container,
    UspsList,
    HeaderCarousel,
  },
  props: {
    usps: VueTypes.array.def([]),
    type: VueTypes.oneOf(['base', 'simple']).def('base'),
  },
}
</script>
