import { mapGetters } from 'vuex'
import { REFER_A_FRIEND_INVITATION_PAGE } from '~/utils/constants/refer-a-friend'
import { IAccountCategories } from '~/models/components/CategoryList'

export default {
  computed: {
    ...mapGetters('context', ['isRafAvailableCountry']),
    ...mapGetters('user', ['isRafEligible', 'isReferred']),
    isRafInvitationPage(): boolean {
      return this.$route.name === REFER_A_FRIEND_INVITATION_PAGE
    },
    showRafElements(): boolean {
      return (
        this.isRafAvailableCountry && (this.isRafEligible || this.isReferred)
      )
    },
    referralProgramMenuItem(): IAccountCategories {
      return {
        title: this.$t('refer_a_friend.referral_program'),
        description: '',
        type: 'link',
        url: this.$contextPath('/refer-a-friend'),
        name: 'referral-program',
        customBehavior: false,
      }
    },
  },
}
